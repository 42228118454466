p {
  /* margin: 0.667em 0 0.667em; */
  /* margin: 0 0 1.66667em; */
}

p + div.highlighter-rouge,
p + twitter-widget,
p + center {
  margin: -0.8em 0 0.8em !important;
}

// code[class*="language-"],
// pre[class*="language-"] {
//   font-size: 0.91em;
// }

// li > code,
// p > code {
//   background: #f8f8f8 !important;
//   border: 1px solid #e8e8e8 !important;
//   color: rgb(68, 68, 68) !important;
//   padding: 0.2em !important;
// }
